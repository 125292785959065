<template>
    <Create v-if="isReady" :data="contractForm" />
</template>

<script>
import { mapActions } from 'vuex';
import Create from './create';

export default {
    components: { Create },
    data: () => {
        return {
            isReady: false,
            contractForm: {
                title: '',
                type: 1,
                contract: '',
                status: 1,
                company_id: null,
                has_signature_certificate: false,
                add_founder_signature: 1,
                founder_name: null,
                founder_email: null,
                founder_signature: {
                    file_url: null,
                    file_path: null,
                }
            },
        };
    },
    mounted() {
        this.getDetailContract();
    },
    methods: {
        ...mapActions({
            getContractDetail: 'contract/getContractDetail',
        }),

        async getDetailContract() {
            const contract = await this.getContractDetail(this.$route.params.id);
            if(contract) {
                this.contractForm = {
                    id: contract._id,
                    title: contract.title,
                    type: contract.type,
                    contract: contract.contract,
                    status: contract.status,
                    company_id: contract.company_id ? {name: contract.company_id.name, _id: contract.company_id._id} : null,
                    has_signature_certificate: contract.has_signature_certificate,
                    add_founder_signature: contract.add_founder_signature,
                    founder_name: contract.founder_name,
                    founder_email: contract.founder_email,
                    founder_signature: contract.founder_signature,
                };
                this.isReady = true;
            }
        }
    }
};
</script>
