<template>
    <section uk-grid>
        <section class="uk-width-3-5">
            <section class="uk-container uk-container-expand uk-margin-top card-scrollable">
                <section class="uk-flex uk-flex-between uk-flex-middle">
                    <button class="uk-button uk-button-default" style="background-color: white; color: black; border: 1px solid black" @click="$router.back()">
                        <img :src="`${images}/back.svg`" alt="Avatar Icon" class="uk-margin-small-right" />
                        Back
                    </button>
                    <button class="uk-button uk-button-primary uk-text-bold uk-text-nowrap" @click="saveContract">
                        Save
                    </button>
                </section>
                <section class="uk-margin-top">
                    <div class="uk-margin">
                        <label class="uk-form-label">Title</label>
                        <input class="uk-input" type="text" v-model="contractForm.title">
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Type</label>
                        <select
                            name="type"
                            class="uk-select"
                            v-model="contractForm.type"
                        >
                            <option value="1" selected>Myrobin Contract</option>
                            <option value="2" >Company Contract</option>
                        </select>
                    </div>
                    <div class="uk-margin" v-if="contractForm.type == 2">
                        <label class="uk-form-label">Company</label>
                        <multiselect
                            v-model="contractForm.company_id"
                            placeholder="Type to search company"
                            label="name"
                            name="company"
                            track-by="_id"
                            :options="company_options"
                            :searchable="true"
                            :loading='isFetching'
                            :internal-search="false"
                            :options-limit="50"
                            @search-change="setCompanyValues"
                            v-validate="'required'"
                        />
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('company')">{{ errors.first('company') }}</span>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Status</label>
                        <select
                            name="type"
                            class="uk-select"
                            v-model="contractForm.status"
                        >
                            <option value="1">Draft</option>
                            <option value="2" >Post</option>
                        </select>
                    </div>
                    <div class="uk-margin">
                        <div class="uk-flex uk-flex-between uk-flex-middle uk-input">
                            <div>Signature Certificate</div>
                            <div>
                                <input v-model="contractForm.has_signature_certificate" class="toggle" type="checkbox" />
                            </div>
                        </div>
                    </div>
                    <div v-if="contractForm.has_signature_certificate" class="uk-margin">
                        <div>
                            <label class="uk-form-label">Add Founder's Signature</label>
                            <select
                                name="type"
                                class="uk-select"
                                v-model="contractForm.add_founder_signature"
                            >
                                <option value="1" selected>After Mitra Sign</option>
                                <option value="2" >Before Mitra Sign</option>
                            </select>
                        </div>
                        <div>
                            <div>&nbsp;</div>
                            <div class="uk-flex uk-flex-middle">
                                <button class="uk-button uk-button-primary uk-border-rounded" @click="$refs.upload.click()">
                                    <img :src="`${images}/cloud-upload.svg`" alt="Avatar Icon" class="uk-margin-small-right" />
                                    Upload Signature File
                                </button>
                                <input ref="upload" style="display: none" type="file" @change="uploadFileVerification" />
                                <button
                                    class="uk-button uk-border-rounded uk-margin-small-left uk-button-default"
                                    :class="!founder_signature && !contractForm.founder_signature ? 'uk-text-black' : 'uk-text-primary'"
                                    :disabled="!founder_signature && !contractForm.founder_signature"
                                    @click="toogleModal('show')"
                                >
                                    <img :src="`${images}/eye.svg`" alt="Avatar Icon" class="uk-margin-small-right" />
                                    Preview
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-if="contractForm.has_signature_certificate" class="uk-margin uk-grid-collapse" uk-grid>
                        <div class="uk-width-1-2">
                            <label class="uk-form-label">Founder's Name</label>
                            <input class="uk-input" type="text" v-model="contractForm.founder_name" style="width:98%" />
                        </div>
                        <div class="uk-width-1-2">
                            <label class="uk-form-label">Founder's Email</label>
                            <input class="uk-input" type="text" v-model="contractForm.founder_email" />
                        </div>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Contract</label>
                        <vue-editor :editor-toolbar="customToolbar" v-model="contractForm.contract"></vue-editor>
                    </div>
                </section>
            </section>
        </section>
        <section class="uk-background-default uk-width-2-5">
            <section class="uk-margin-right uk-margin-top card-scrollable">
                <h5 class="uk-text-bold">Guide</h5>
                <p>Untuk mengisi Contract, terdapat beberapa variabel yang dapat berubah secara dinamis bergantung dari nilai data itu sendiri</p>
                <p>Untuk penggunaan variabel dinamis, gantilah penulisan di dalam contract sesuai dengan catatan berikut:</p>
                <section class="uk-grid-small" uk-grid>
                    <section class="uk-width-1-2" v-for="(selector, sKey) in selectors" :key="sKey">
                        <div class="uk-background-muted" style="padding: 10px;">
                            <div class="uk-flex uk-flex-middle">
                                <img :src="`${images}/${selector.icon}`" alt="Avatar Icon" class="uk-margin-small-right" />
                                <p
                                    class="uk-margin-remove uk-text-small uk-text-bold"
                                    style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                                    :title="selector.label"
                                >
                                    {{ selector.label }}
                                </p>
                            </div>
                            <p class="uk-margin-remove-bottom uk-text-small" style="word-break: break-all;">{{ selector.value }}</p>
                        </div>
                    </section>
                </section>
                <p>Contoh penulisan di dalam contract adalah seperti berikut:</p>
                <p>{name}, pemegang KTP dengan NIK {ktp} beralamat pada {address} dalam hal ini bertindak atas namanya sendiri, selanjutnya disebut sebagai "Mitra"</p>
            </section>
        </section>

        <div v-if="contractForm.has_signature_certificate && contractForm.founder_signature" id="previewSignatureModal" class="uk-modal-container" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog" uk-overflow-auto>
                <button class="uk-modal-close-full uk-close-large" type="button" uk-close @click="toogleModal('hide')" />
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Preview</h2>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <img :src="contractForm.founder_signature.file_url" alt="signature" height="350" style="margin: auto; display: block">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { PREFIX_IMAGE } from "@/utils/constant";
import { VueEditor } from 'vue2-editor';
import { notificationSuccess, notificationDanger } from '@/utils/notification';

export default {
    props: [ "data" ],
    components: {
        VueEditor
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            customToolbar: [
                [{ 'header': [false, 1, 2, 3, 4, 5, 6] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'align': '' }, { 'align': 'center' },{ 'align': 'right' }, { 'align': 'justify' }],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'indent': '-1'}, { 'indent': '+1' }],
                [{ 'color': [] }, { 'background': [] }],
                ['link'],
                ['clean']
            ],
            founder_signature: null,
            contractForm: {
                title: '',
                type: 1,
                contract: '',
                status: 1,
                company_id: null,
                has_signature_certificate: false,
                add_founder_signature: 1,
                founder_name: null,
                founder_email: null,
                founder_signature: null
            },
            company_options: [],
            isFetching: false,
            selectors: [
                { label: "Nama Mitra", value: "{name}", icon: "/mdi_user-tie.svg" },
                { label: "No. KTP Mitra", value: "{ktp}", icon: "/bxs_id-card.svg" },
                { label: "No. Hp Mitra", value: "{phone_number}", icon: "/ic_round-phone-iphone.svg" },
                { label: "ID Mitra", value: "{employee_id}", icon: "/ion_id-card.svg" },
                { label: "Jenis Kelamin", value: "{gender}", icon: "/healthicons_sexual-reproductive-health.svg" },
                { label: "Alamat Mitra", value: "{address}", icon: "/bxs_home.svg" },
                { label: "Tempat Lahir", value: "{birthplace}", icon: "/baby-head.svg" },
                { label: "Tanggal Lahir", value: "{birthdate}", icon: "/birthday-cake.svg" },
                { label: "Bank milik Mitra", value: "{bank_name}", icon: "/fluent_building-bank-20-filled.svg" },
                { label: "No. Rekening Bank Mitra", value: "{bank_account_number}", icon: "/ic_round-account-balance-wallet.svg" },
                { label: "Nama Perusahaan", value: "{company_name}", icon: "/fluent_building-bank-20-filled.svg" },
                { label: "Jenis Usaha", value: "{jenis_usaha}", icon: "/ic_round-miscellaneous-services.svg" },
                { label: "Alamat Perusahaan", value: "{company_address}", icon: "/carbon_location-company-filled.svg" },
                { label: "Office name", value: "{office_name}", icon: "/heroicons_building-office-2-20-solid.svg" },
                { label: "Role name", value: "{role_name}", icon: "/ic_round-work.svg" },
                { label: "Salary setup", value: "{salary_setup}", icon: "/ic_round-miscellaneous-services.svg" },
                { label: "Fee Normal", value: "{fee_normal}", icon: "/fluent_money-20-filled.svg" },
                { label: "Fee Normal Unit", value: "{fee_normal_unit}", icon: "/fluent_money-settings-20-filled.svg" },
                { label: "Fee Overtime", value: "{fee_overtime}", icon: "/fluent_money-calculator-20-filled.svg" },
                { label: "NPWP", value: "{npwp}", icon: "/document-with-percent.svg" },
                { label: "Tanggal Mitra mulai bekerja", value: "{job_start}", icon: "/bxs_calendar-check.svg" },
                { label: "Tanggal Mitra akhir bekerja", value: "{job_end}", icon: "/bxs_calendar-x.svg" },
                { label: "Tanggal Penandatanganan", value: "{signed_date}", icon: "/ic_round-edit-calendar.svg" },
                { label: "TTD Contract dari Mitra", value: "{ttd}", icon: "/fluent_signature-20-filled.svg" },
                { label: "TTD Contract dari MyRobin", value: "{ttd_founder}", icon: "/la_file-signature.svg" },
                { label: "No Telepon Kontak Darurat (1)", value: "{no_emergency_contact_1}", icon: "/asterisk.svg" },
                { label: "Nama Kontak Darurat (1)", value: "{name_emergency_contact_1}", icon: "/contact-card.svg" },
                { label: "Hubungan Kontak Darurat (1)", value: "{relation_emergency_contact_1}", icon: "/family-people.svg" },
                { label: "No Telepon Kontak Darurat (2)", value: "{no_emergency_contact_2}", icon: "/asterisk.svg" },
                { label: "Nama Kontak Darurat (2)", value: "{name_emergency_contact_2}", icon: "/contact-card.svg" },
                { label: "Hubungan Kontak Darurat (2)", value: "{relation_emergency_contact_2}", icon: "/family-people.svg" },
                { label: "Kuota Cuti", value: "{quota_leave}", icon: "/clock.svg" },
            ]
        };
    },
    mounted() {
        this.contractForm = this.$props.data ?? this.contractForm;
    },
    computed : {
        ...mapGetters({
            companies: 'company/companies',
        })
    },
    methods: {
        ...mapActions({
            // uploadImagePDF: 'file/uploadImagePDF',
            uploadImgFounderSignature: 'contract/uploadImgFounderSignature',
            getCompanies: 'company/getCompanies',
            createContract: 'contract/createContract',
            updateContract: 'contract/updateContract',
        }),
        async setCompanyValues(query) {
            this.isFetching = true;

            await this.getCompanies({name: query});
            this.company_options = this.companies.docs.map(
                obj => ({name: obj.name, _id: obj._id})
            );

            this.isFetching = false;
        },
        toogleModal(state) {
            if (state === 'show') {
                if (this.founder_signature instanceof File) {
                    const reader = new FileReader();
                    reader.readAsDataURL(this.founder_signature);
                    reader.onload = () => {
                        this.contractForm.founder_signature.file_url = reader.result;
                    };
                }
                window.UIkit.modal('#previewSignatureModal').show();

            } else {
                window.UIkit.modal('#previewSignatureModal').hide();
            }
        },
        async saveContract(){
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                // if (this.contractForm.has_signature_certificate && !this.founder_signature) {
                //     throw ('Founder signature must be uploaded!');
                // }

                if (this.founder_signature instanceof File)
                    await this.uploadFounderSignature();

                this.contractForm.company_id = this.contractForm.company_id ? this.contractForm.company_id._id : null;
                const response = this.contractForm.id
                    ? await this.updateContract(this.contractForm)
                    : await this.createContract(this.contractForm);
                if (response && response.status === 'OK') {
                    notificationSuccess('Contract saved!');
                    this.$router.back();
                } else {
                    notificationDanger('Failed to save contract.');
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async uploadFounderSignature() {
            const formData = new FormData();
            formData.append('file', this.founder_signature);

            // const result = await this.uploadImagePDF({ folder: 'job-contract', category: 'founder-signature', data: formData });
            const result = await this.uploadImgFounderSignature(formData);

            if (result.status === 'OK') {
                notificationSuccess(`Upload successful`);
                this.contractForm.founder_signature = result.result;
            } else {
                notificationDanger('Failed to upload!');
            }
        },
        async uploadFileVerification(e) {
            if (e.target.files) {
                if (this.checkFile(e.target.files[0].size, e.target.files[0].type)) {
                    this.founder_signature = e.target.files[0];
                    this.$refs.upload.value = null;
                }
            }
        },
        checkFile(fileSize, fileExt) {
            if (fileSize / 1024 / 1024 > 10) {
                notificationDanger('File terlalu besar, maksimal file berukuran 10 MB');
                this.$refs.upload.value = null;
                return false;
            }

            const extension = ["png", "jpg", "jpeg", "pdf"];
            const splitImg = fileExt.split("/");
            let imgMime = splitImg[splitImg.length - 1];

            if (!extension.includes(imgMime)) {
                notificationDanger('Ekstensi file tidak dikenali');
                this.$refs.upload.value = null;
                return false;
            }

            return true;
        },
    }
};
</script>

<style scoped>
.toggle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 35px;
  height: 15px;
  display: inline-block;
  position: relative;
  border-radius: 50px;
  overflow: hidden;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #707070;
  -webkit-transition: background-color ease 0.3s;
  transition: background-color ease 0.3s;
}

.toggle:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 2;
  width: 20px;
  height: 20px;
  background: #fff;
  left: 2px;
  top: -3px;
  border-radius: 50%;
  font: 10px/28px Helvetica;
  font-weight: normal;
  text-transform: uppercase;
  font-weight: bold;
  text-indent: -22px;
  word-spacing: 37px;
  color: #fff;
  text-shadow: -1px -1px rgba(0,0,0,0.15);
  white-space: nowrap;
  -webkit-box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  -webkit-transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
  transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
  left: 0;
}

.toggle:checked {
  background-color: #0abab5;
}

.toggle:checked:before {
  left: 20px;
}
</style>
